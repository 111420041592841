const nonoDados = [
  {
    id: 1,
    url: "/anos/nono/modals",
    text: "Modals",
  },
  {
    id: 2,
    url: "/anos/nono/conditionals",
    text: "Conditionals",
  },
];

export default nonoDados;
